import { FC, PropsWithChildren } from "react";
import { IStatusLogStatus } from "../../model/IOrder";
import { statusColor } from "./StatusColors";

const StatusText: FC<{
  status: IStatusLogStatus,
  size?: 'small' | 'medium' | 'large',
}> = ({
  status,
  size = 'medium',
}) => (
  <span
    style={{ color: statusColor?.[status] || 'gray' }}
    className={`
      ${{
        'small': '',
        'medium': 'text-md',
        'large': 'text-lg',
      }?.[size] || ''}  
    `}
  >
    {status}
  </span>
);

export default StatusText;
