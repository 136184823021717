import { FC } from "react";
import useTicket from "../../hooks/useTicket";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { icons } from "../../icons/IconSet";
import { getOrder } from "../../store/orderSlice";
import StatusText from "../status/StatusText";
import ButtonResendInvoice from "./ButtonResendInvoice";
import ButtonViewInvoice from "./ButtonViewInvoice";

const OrderHeader: FC = () => {
  const order = useTypedSelector(getOrder());
  const { refreshTickets } = useTicket();

  return !order?.id
    ? null
    : (
      <div className="flex justify-between">
        <div className="text-text">
          <div className="text-xl sm:text-3xl title-bold">ORDER MANAGEMENT</div>
          <div className="sm:text-lg flex gap-4 items-center">
            <div>Order # {order.orderId}</div>
            <div>
              <div
                role="button"
                tabIndex={-1}
                onClick={refreshTickets}
                className="text-gray-500 hover:text-primary"
              >
                {icons('refresh', { width: '18px' })}
              </div>
            </div>            
          </div>
          {!!order?.statusLog?.length && (
            <div className="">
              <StatusText status={order.statusLog?.[order.statusLog.length - 1]?.status} size="large" />
            </div>
          )}      
        </div>    
        <div className="flex flex-col gap-1">          
          <ButtonViewInvoice />
          <ButtonResendInvoice />
        </div>
      </div>
    );
};

export default OrderHeader;
