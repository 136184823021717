import { FC } from "react";
import priceFormatter from "../../utils/priceFormatter";
import arrayToNodeString from "../../utils/arrayToNodeString";
import { IOrderItem } from "../../model/IOrder";
import { icons } from "../../icons/IconSet";

type TOrderItemSize = 'small' | 'medium' | 'large';

const OrderItem: FC<IOrderItem & {
  isSelected?: boolean,
  select?: () => unknown,
  size?: TOrderItemSize,
}> = ({
  partTypeCode,
  name,
  viYear,
  viModel,
  viMake,
  pid,
  price,
  isSelected,
  select,
  size = 'large',
}) => {
  const scale: Record<TOrderItemSize, {
    className: string,
    imgW: number,
    gap: number,
  }> = {
    small: { className: 'lg:w-[466px]', imgW: 100, gap: 0 },
    medium: { className: 'lg:w-[466px]', imgW: 115, gap: 0 },
    large: { className: 'lg:w-[466px]', imgW: 150, gap: 2 }
  }

  return (
    <div
      onClick={() => select && select()}
      className={`
        flex flex-col  border-[1px] rounded-md p-2 transition-all
        ${isSelected
          ? 'border-primary'
          : select 
            ? 'border-border_color'
            : 'border-none'}
        ${select
          ? 'hover:border-primary_hover cursor-pointer'
          : ''}
        ${scale[size].className}
      `}
    >     
      <div className="flex gap-4">
        <div className="">
          {!!partTypeCode         
            ? (
              <img
                src={`https://automotix.net/images/products_small/lkq/${partTypeCode.toLowerCase()}_category.jpg`}
                width={scale[size].imgW}
                height={scale[size].imgW}
                alt={`${name || ''} for ${viYear || ''} ${viMake || ''} ${viModel || ''}`} 
              />
            ) : (
              <div
                style={{
                  width: scale[size].imgW,
                  height: scale[size].imgW,
                }}
                className="flex justify-center items-center bg-slate-200"
              >
                {icons('package', { width: `${scale[size].imgW * 0.75}px`, stroke: 'white' })}
              </div>
            )
          }
        </div>
        <div
          style={{
            gap: scale[size].gap,
            paddingTop: scale[size].gap * 4,
          }}
          className="flex flex-col"
        >
          <div className="font-bold">
            {arrayToNodeString(' ', [viYear, viMake, name])}
          </div>
          <div className="">
            {viModel || ''}
          </div>
          <div className="text-red-600 font-bold">
            {name}
          </div>
          <div className="">
            Part#: {pid}
          </div>
          <div className="text-md font-bold mt-2">
            {priceFormatter(price)}
          </div>
        </div>
      </div>
    </div>
  );
}


export default OrderItem;
