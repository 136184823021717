import { FC, PropsWithChildren} from "react";

const CenterLayout: FC<PropsWithChildren> = ({ children }) => (
  <div className="h-full stylledScroll">
    <main>
      <div className="flex min-h-[100vh] bg-white flex-col justify-center py-12 px-4 sm:px-6 lg:px-8">   
        {children}
      </div>
    </main>
</div>
);

export default CenterLayout;
