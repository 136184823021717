import { FC, PropsWithChildren } from "react";

const Infoblock: FC<PropsWithChildren & { title?: string }> = ({ title, children }) => (
  <div className="flex flex-col gap-1">
    {!!title && <div className="font-bold">{title}</div>}    
    <div className="">{children}</div>
  </div>
);

export default Infoblock;
