import { FC } from "react";
import { IOrder } from "../../model/IOrder";
import { NavLink } from "react-router-dom";
import Button from "../form/Button";
import { icons } from "../../icons/IconSet";
import Title from "../title/Title";
import SubTitle from "../title/SubTitle";
import Infoblock from "../infoblock/Infoblock";

const OrderShipment: FC<IOrder> = ({ siCarrierLink, siCarrier, siTrackingNum }) => (
  <div className="flex flex-col gap-4">
    <Title>Track Shipment</Title>
    {siCarrierLink
      ? (
        <>
          Your order is being processed and has not been shipped yet. We’ll send you an update when it has been shipped.
        </>
      ) : (
        <>
          <SubTitle>Reference Numbers</SubTitle>
          <div className="flex gap-10">
            <Infoblock title="Track #">
              {siTrackingNum}
            </Infoblock>
          </div>

          <div className="">
            <SubTitle>Track your shipment on {siCarrier ?? ''} (external site)</SubTitle>
            Clicking on the button below will open an external site to track your shipment.
          </div>      

          <NavLink to={siCarrierLink || ''} target="_blank" className="mt-2">
              <Button isNotFullWidth variant="outline">
              Track Shipment On {siCarrier ?? ''}
            </Button>
          </NavLink>
        </>
      )        
  }      
</div>   
);

export default OrderShipment;
