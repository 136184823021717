import { FC } from "react";
import Title from "./title/Title";
import { IOrderSignInState } from "../model/IOrderSignIn";
import useOrder from "../hooks/useOrder";

const AuthParamsHistory: FC<{ setState: React.Dispatch<React.SetStateAction<IOrderSignInState>>}> = ({ setState }) => {
  const { getAuthParamsHistory } = useOrder();

  return !getAuthParamsHistory()?.length
    ? null
    : (
      <div className="grid gap-4 w-[600px]">
        <Title>Recent Order Searches</Title>

        <div className="w-full">
          {(getAuthParamsHistory() || []).map(({ orderId, zip, name }) => (
            <div
              className="flex cursor-pointer hover:bg-gray-50 p-3 justify-between border-t-[1px] border-t-gray-200 w-full"
              key={orderId}
              onClick={() => setState({ orderId, zip, name })}
            >
              <div className="flex flex-col w-[40%]">
                <div className="text-gray-400">Name</div>
                <div className="text-gray-700 font-bold">{name}</div>
              </div>
              <div className="flex flex-col w-[30%]">
                <div className="text-gray-400">Zipcode</div>
                <div className="text-gray-700 font-bold">{zip}</div>
              </div>
              <div className="flex flex-col w-[30%]">
                <div className="text-gray-400">Order #</div>
                <div className="text-gray-700 font-bold">{orderId}</div>
              </div>
            </div>
          ))}
        </div>      
      </div>
  );
};

export default AuthParamsHistory;
