import { FC, PropsWithChildren } from "react";
import { useLocation, useNavigate } from "react-router";
import Button from "../form/Button";

const OrderButton: FC<PropsWithChildren & { to?: string }> = ({ children, to }) => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Button
      variant={location.pathname === to ? 'filled' : 'outline'}
      size="small"
      onClick={() => to && navigate(to)}
    >
      {children}
    </Button>
  );
};

export default OrderButton;
