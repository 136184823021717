import { FC, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useHandleField } from "../../hooks/useHandleField";
import useTicket, { IMsgForm } from "../../hooks/useTicket";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { IOrderItem } from "../../model/IOrder";
import { ITicket } from "../../model/ITicket";
import { msg } from "../../msg";
import { getDictionary } from "../../store/ticketCommonSlice";
import { getTickets } from "../../store/ticketSlice";
import Loading from "../common/Loading";
import RadioList from "../form/RadioList";
import TicketMessage from "../tickets/TicketMessage";
import TicketMessages from "../tickets/TicketMessages";
import TimeLineVertical from "../TimeLineVertical";
import SubTitle from "../title/SubTitle";
import Title from "../title/Title";
import OrderItem from "./OrderItem";

export interface IReturnForm {
  additionalDetails: string,
  attachments?: File[],
  reason: string,
  orderItemId: number,
}

const OrderReturnItem: FC<IOrderItem> = ({ ...activeOrderItem }) => {
  const tickets = useTypedSelector(getTickets());
  const dictionary = useTypedSelector(getDictionary());
  const { addReturn, addMsg } = useTicket();
  const [loading, setLoading] = useState<boolean>(false);   

  // --
  // -- Add Ticket flow
  // --
  const initReturnForm: IReturnForm = {
    additionalDetails: '',
    attachments: [],
    reason: '',
    orderItemId: activeOrderItem.id,
  };

  const [state, setState] = useState<IReturnForm>(initReturnForm);
  const [stateErr, setStateErr] = useState<Partial<Record<keyof IReturnForm, string>>>(); 
  const handleField = useHandleField(setState);

  const handleAddTicket = async () => {
    setLoading(() => true);
    setStateErr(() => undefined);
    const errs = await addReturn(state);
    if (errs)
      setStateErr(() => errs);
    else {
      toast.success(msg('return request was sended successfully'));      
      setState(() => initReturnForm);
    }
    setLoading(() => false);
  };   

  // --
  // -- Edit the existed ticket flow
  // --
  const [currentTicket, setcurrentTicket] = useState<ITicket | null>();
  const { searchTicketByTypeIds } = useTicket(); 
  useEffect(() => {    
    setcurrentTicket(() => searchTicketByTypeIds({
      typeIds: [2, 6, 7, 8, 9],
      searchOrderItemId: activeOrderItem?.id,
      ticketArr: tickets,
    }));
  
    setState((prevState) => ({
      ...prevState,
      orderItemId: activeOrderItem.id,
    }));
  }, [activeOrderItem?.id, tickets]);  

  const initReturnMsgForm: IMsgForm = {
    message: '',
    ticketId: currentTicket?.id ? String(currentTicket.id) : '',
  };

  const [stateMsg, setStateMsg] = useState<IMsgForm>(initReturnMsgForm);
  const [stateMsgErr, setStateMsgErr] = useState<Partial<Record<keyof IMsgForm, string>>>();
  const handleMsgField = useHandleField(setStateMsg);

  useEffect(() => {
    setStateMsg((prevState) => ({
      ...prevState,
      ticketId: currentTicket?.id ? String(currentTicket.id) : '',
    }));
  }, [currentTicket?.id]);
  
  const handleAddMsg = async () => {
    setLoading(() => true);
    setStateMsgErr(() => undefined);
    const errs = await addMsg(stateMsg);   
    if (errs)
      setStateMsgErr(() => errs);
    else {
      toast.success(msg('message was sended successfully'));      
      setStateMsg(() => initReturnMsgForm);
    }
    setLoading(() => false);
  };
  
  return currentTicket === undefined
    ? <Loading />
    : (
      <div className={`flex flex-col gap-4 ${!!loading && 'animate-pulse'}`}>
        <Title>Return Item</Title>
        <OrderItem {...activeOrderItem} size="medium" />       

        {!!currentTicket && (
          <>
            <SubTitle>Return Status</SubTitle>
            <TimeLineVertical ticket={currentTicket} />
          </>
        )}
        
        {currentTicket === null
          ? (
            <>
              <SubTitle>Select the reason why you want to return these items (required)</SubTitle>
              {!!dictionary?.returns?.length && (
                <RadioList
                  name="reason"
                  value={state.reason}
                  handle={handleField('reason')}
                  options={dictionary.returns.map(({ id, type, text }) => ({
                    title: text,
                    value: id,
                  }))}
                  error={stateErr?.reason || ''}
                />
              )}
              <SubTitle>Please describe the issue in detail and upload any supporting photos (required)</SubTitle>
              <div>Upload photos of the part, odometer (if applicable) and vehicle</div>

              <TicketMessage
                label="Additional Details"
                name="details"
                placeholder="Please describe in detail"
                value={state.additionalDetails}
                handle={handleField('additionalDetails')}
                error={stateErr?.additionalDetails || ''}
                disabled={loading}
                setFileState={(attachments: File[]) => setState((prevState) => ({
                  ...prevState,
                  attachments,
                }))}
                files={state.attachments}
                handleSend={handleAddTicket}
              />
            </>
          ) : (
            <>
              {currentTicket?.messages?.length && (
                <div className="mt-6">
                  <div className="mb-4 font-semibold">Messages</div>
                  <TicketMessages ticket={currentTicket} />
                </div>
              )}
              <TicketMessage
                label={ !currentTicket?.messages?.length ? 'Additional Details' : ''}
                name="details"
                placeholder={ !currentTicket?.messages?.length ? 'Please describe in additional detail' : 'Message Customer Support...'}
                value={stateMsg.message}
                handle={handleMsgField('message')}
                error={stateMsgErr?.message || ''}
                disabled={loading}
                setFileState={(attachments: File[]) => setStateMsg((prevState) => ({
                  ...prevState,
                  attachments,
                }))}
                files={stateMsg.attachments}
                handleSend={handleAddMsg}
                butTitle={ !currentTicket?.messages?.length ? 'Continue' : 'Send Message'}
              />
            </>
          )        
        }
      </div>
    );
};

export default OrderReturnItem;
