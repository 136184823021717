import { FC, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useHandleField } from "../../hooks/useHandleField";
import useTicket, { IMsgForm } from "../../hooks/useTicket";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { ITicket } from "../../model/ITicket";
import { msg } from "../../msg";
import { getDictionary } from "../../store/ticketCommonSlice";
import { getTickets } from "../../store/ticketSlice";
import Loading from "../common/Loading";
import RadioList from "../form/RadioList";
import TicketMessage from "../tickets/TicketMessage";
import TicketMessages from "../tickets/TicketMessages";
import SubTitle from "../title/SubTitle";
import Title from "../title/Title";
import parseISOToString from "../../utils/parseISOToString";
import TimeLineVertical from "../TimeLineVertical";

export interface ICancelForm {
  additionalDetails: string,
  attachments?: File[],
  reason: string,
}

const OrderCancel: FC = () => {
  const tickets = useTypedSelector(getTickets());
  const dictionary = useTypedSelector(getDictionary());
  const { addCancel, addMsg } = useTicket();
  const [loading, setLoading] = useState<boolean>(false);   

  // --
  // -- Add Ticket flow
  // --
  const initReturnForm: ICancelForm = {
    additionalDetails: '',
    attachments: [],
    reason: '',
  };

  const [state, setState] = useState<ICancelForm>(initReturnForm);
  const [stateErr, setStateErr] = useState<Partial<Record<keyof ICancelForm, string>>>(); 
  const handleField = useHandleField(setState);

  const handleAddTicket = async () => {
    setLoading(() => true);
    setStateErr(() => undefined);
    const errs = await addCancel(state);
    if (errs)
      setStateErr(() => errs);
    else {
      toast.success(msg('cancel order request was sended successfully'));      
      setState(() => initReturnForm);
    }
    setLoading(() => false);
  };  


  // --
  // -- Edit the existed ticket flow
  // --
  const [currentTicket, setcurrentTicket] = useState<ITicket | null>();
  const { searchTicketByTypeIds } = useTicket(); 
  useEffect(() => {    
    setcurrentTicket(() => searchTicketByTypeIds({
      typeIds: [3],
      ticketArr: tickets,
    }))}, [tickets]);  

  const initMsgForm: IMsgForm = {
    message: '',
    ticketId: currentTicket?.id ? String(currentTicket.id) : '',
  };

  const [stateMsg, setStateMsg] = useState<IMsgForm>(initMsgForm);
  const [stateMsgErr, setStateMsgErr] = useState<Partial<Record<keyof IMsgForm, string>>>();
  const handleMsgField = useHandleField(setStateMsg);

  useEffect(() => {
    setStateMsg((prevState) => ({
      ...prevState,
      ticketId: currentTicket?.id ? String(currentTicket.id) : '',
    }));
  }, [currentTicket?.id]);
  
  const handleAddMsg = async () => {
    setLoading(() => true);
    setStateMsgErr(() => undefined);
    const errs = await addMsg(stateMsg);   
    if (errs)
      setStateMsgErr(() => errs);
    else {
      toast.success(msg('message was sended successfully'));      
      setStateMsg(() => initMsgForm);
    }
    setLoading(() => false);
  };

  return currentTicket === undefined
    ? <Loading />
    : (
      <div className={`flex flex-col gap-4 ${!!loading && 'animate-pulse'}`}>
        <Title>Cancel Order</Title>    
        <div>Please submit the form below to request to cancel your order.  We will attempt to cancel the order before it is shipped. If we succeed, you will receive a full refund.</div>
        <div>If your order has already shipped, we will let you know and give you the choice to turn the order around or allow delivery to continue.  If we turn the order around, your refund will be less the original shipping and the cost of returning to our warehouse.</div>

        {!!currentTicket && (
          <>
            <SubTitle>Cancel Order Status</SubTitle>
            <TimeLineVertical ticket={currentTicket} />
          </>
        )}

        {currentTicket === null
          ? (
            <>
              <SubTitle>Select the reason why you want to cancel this order (required)</SubTitle>
              {!!dictionary?.cancel?.length && (
                <RadioList
                  name="reason"
                  value={state.reason}
                  handle={handleField('reason')}
                  options={dictionary.cancel.map(({ id, type, text }) => ({
                    title: text,
                    value: id,
                  }))}
                  error={stateErr?.reason || ''}
                />
              )}
              <SubTitle>Please provide any additional details (required)</SubTitle>

              <TicketMessage
                label="Additional Details"
                name="details"
                placeholder="Please describe in detail"
                value={state.additionalDetails}
                handle={handleField('additionalDetails')}
                error={stateErr?.additionalDetails || ''}
                disabled={loading}
                setFileState={(attachments: File[]) => setState((prevState) => ({
                  ...prevState,
                  attachments,
                }))}
                files={state.attachments}
                handleSend={handleAddTicket}
              />
            </>
          ) : (
            <>
              {currentTicket?.messages?.length && (
                <div className="mt-6">
                  <div className="mb-4 font-semibold">Messages</div>
                  <TicketMessages ticket={currentTicket} />
                </div>
              )}
              <TicketMessage
                label={ !currentTicket?.messages?.length ? 'Additional Details' : ''}
                name="details"
                placeholder={ !currentTicket?.messages?.length ? 'Please describe in additional detail' : 'Message Customer Support...'}
                value={stateMsg.message}
                handle={handleMsgField('message')}
                error={stateMsgErr?.message || ''}
                disabled={loading}
                setFileState={(attachments: File[]) => setStateMsg((prevState) => ({
                  ...prevState,
                  attachments,
                }))}
                files={stateMsg.attachments}
                handleSend={handleAddMsg}
                butTitle={ !currentTicket?.messages?.length ? 'Continue' : 'Send Message'}
              />              
            </>
          )        
        }
      </div>
    );
};

export default OrderCancel;
