import { FC } from "react";
import Input, { IInput } from "../form/Input";
import FileUpload from "../files/FileUpload";
import Button from "../form/Button";

const TicketMessage: FC<IInput & {
  setFileState: (files: File[]) => unknown,
  files?: File[],
  handleSend: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined) => void,
  butTitle?: string,
}> = ({
  disabled,
  setFileState,
  files,
  handleSend,
  butTitle = 'Continue',
  ...props
}) => (
  <>
    <Input
      type="textarea"
      disabled={disabled}
      className="max-w-[450px]"
      classNameField="h-[138px]"
      {...props}
    />
      <div className="max-w-[450px]">
        <FileUpload
          name="files"
          disabled={disabled}
          filesState={files}
          setFileState={setFileState}
        />
      </div>
      <div className="flex">
        <Button
          variant="outline"
          disabled={!props?.value || disabled}
          isNotFullWidth
          onClick={handleSend}
        >
          {butTitle}
        </Button>
      </div>
  </>
);

export default TicketMessage;
