import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '.';
import { ITicket } from '../model/ITicket';


export interface ITicketInitState {
  tickets?: ITicket[],
}

const ticketSlice = createSlice({
  name: 'ticketSlice',
  initialState: {
    tickets: undefined,
  } as ITicketInitState,

  reducers: {
    setTickets: (state: ITicketInitState, action: PayloadAction<ITicket[]>) => { state.tickets = action.payload; },
    removeTickets: (state: ITicketInitState) => { state.tickets = undefined; },
  },
});

// Reducers
export default ticketSlice.reducer;
export const {
  setTickets,
  removeTickets,
} = ticketSlice.actions;

// Selectors
export const getTickets = () => (state: RootState) => state.ticketSlice.tickets;
