import { Dispatch, FC, SetStateAction } from "react";
import Logo from "./Logo/Logo";
import { IOrderSignInState } from "../model/IOrderSignIn";
import { useHandleField } from "../hooks/useHandleField";
import Input from "./form/Input";
import Button from "./form/Button";
import Alert from "./common/Alert";

const OrderSignInForm: FC<{ 
  state: IOrderSignInState,
  stateErr?: Partial<Record<keyof IOrderSignInState, string>>,
  setState: Dispatch<SetStateAction<IOrderSignInState>>,
  loading?: boolean,
  handleOrderSingIn: () => unknown,
}> = ({
  state,
  stateErr,
  setState,
  loading,
  handleOrderSingIn,
}) => {

  const handleField = useHandleField(setState); 

  return (
    <>
      <div className="sm:mx-auto sm:w-full sm:max-w-md flex justify-center">
        <Logo size="medium" />
      </div>
      <div className="mt-6 sm:mx-auto sm:w-full sm:max-w-[400px] border-[1px] border-border_color border-solid rounded-md">
        <div className="bg-white px-6 py-8 shadow sm:rounded-lg sm:px-12">
          <form className="space-y-6"> 
            {!!(stateErr && Object.keys(stateErr).length) && (
              <Alert>
                Cannot find order with the information provided. Please enter the correct information and try again.
              </Alert>
            )}       
            <Input
              label="Order Number"
              name="orderId"
              size="large"
              value={state.orderId}
              handle={handleField('orderId')}
              disabled={loading}
              error={stateErr && Object.keys(stateErr).length ? ' ' : undefined}
              // error={stateErr?.orderId}
            />
            <Input
              label="Zip Code (Billing)"
              name="zip"
              size="large"
              value={state.zip}
              handle={handleField('zip')}
              error={stateErr && Object.keys(stateErr).length ? ' ' : undefined}
              // error={stateErr?.zip}
              disabled={loading}
            />
            <Input
              label="Full Name"
              name="name"
              size="large"
              value={state.name}
              handle={handleField('name')}
              error={stateErr && Object.keys(stateErr).length ? ' ' : undefined}
              // error={stateErr?.name}
              disabled={loading}
            />
            <div>
              <Button
                size="large"
                onClick={handleOrderSingIn}
                disabled={loading}
              >
                Retrieve Order
              </Button>              
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default OrderSignInForm;
