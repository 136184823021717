import { FC } from "react";
import { useTypedDispatch } from "../../hooks/useTypedDispatch";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { getActiveOrderItemId, getOrder, setActiveOrderItemId } from "../../store/orderSlice";
import OrderButton from "./OrderButton";
import OrderItem from "./OrderItem";

const OrderInfo: FC = () => {
  const dispatch = useTypedDispatch();
  const order = useTypedSelector(getOrder());
  const activeOrderItemId = useTypedSelector(getActiveOrderItemId());
  if (!order) return null; 

  return (
    <div className="border-border_color border-[1px] rounded-md p-4">
      <div className="flex flex-col-reverse sm:flex-row gap-4 justify-between">
        <div className="flex flex-col gap-4">
          {(order?.orderItems || []).map((orderItem) => (
            <OrderItem
              key={`order-item-${orderItem.id}`}
              isSelected={activeOrderItemId === orderItem.id}
              select={() => dispatch(setActiveOrderItemId(orderItem.id))}
              {...orderItem}
            />))}
        </div>
        <div className="flex flex-col gap-1">
          <OrderButton to="/order/shipment">Track Shipment</OrderButton>
          {/* <OrderButton to="/order/warranty">Buy Extended Warranty</OrderButton> */}
          <OrderButton to="/order/contact">Contact Us</OrderButton>
          <div className="mt-4" />
          <OrderButton to="/order/return">Return Item</OrderButton>
          <OrderButton to="/order/cancel">Cancel Order</OrderButton>
        </div>
      </div>
    </div>
  );
}

export default OrderInfo;
