import { IStatusLogStatus } from "../../model/IOrder";

export const statusColor: Record<IStatusLogStatus, string> = {
  'Initiated': '#3665f3',
  'Cancelled by Customer': '#be185d',
  'Refunded': '#6b21a8',
}

// export interface IPaletteStatus {
//   color: string,
//   text: string,
//   bg: string,
//   from: string,
//   via: string,
//   to: string,
// };

// export const statusPalette: Record<IStatusLogStatus, IPaletteStatus> = 
//   Object.entries(statusColor).reduce((acc, [status, color]) => ({
//     ...acc,
//     [status]: {
//       color,
//       text: `text-[${color}]`,
//       bg: `bg-[${color}]`,
//       from: `from-[${color}]`,
//       via: `via-[${color}]`,
//       to: `to-[${color}]`,
//     }
//   }), {} as Record<IStatusLogStatus, IPaletteStatus>);
