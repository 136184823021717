import { FC } from "react";
import Title from "../title/Title";
import SubTitle from "../title/SubTitle";
import Infoblock from "../infoblock/Infoblock";
import { IOrder } from "../../model/IOrder";
import arrayToNodeString from "../../utils/arrayToNodeString";
import priceFormatter from "../../utils/priceFormatter";
import ButtonViewInvoice from "./ButtonViewInvoice";
import StatusLog from "../status/StatusLog";
import TimeLineVertical from "../TimeLineVertical";
import { title } from "process";

const OrderDetails: FC<IOrder> = ({
  piName,
  piPhone,
  piEmail,
  siName,
  siStreet,
  siState,
  siZip,
  siCountry,
  siCompany,
  subtotalPrice,
  shipping,
  taxes,
  totalPrice,
  statusLog,
  timeCreate,
}) => (
  <div className="flex flex-col gap-4">
    <Title>Order Details</Title>

    <SubTitle>Order Status</SubTitle>
    <div className="hidden lg:flex">
      <StatusLog log={statusLog} created={timeCreate} />
    </div>
    <div className="flex lg:hidden">
      {/* <TimeLineVertical list={[
        {
          status: 'passed',
          title: 'Initiated',
          description: timeCreate,
        },
        ...(statusLog.map(({ status, date }) => ({
          status: "passed" as 'passed' | 'ahead',
          title: status,
          description: date,
        })))
      ]} /> */}
    </div>
    
    <div className="mb-4 mt-1"></div>

    <SubTitle>Order Summary</SubTitle>
    <div className="flex flex-col sm:flex-row gap-10">
      <Infoblock title="Customer Information">
        {arrayToNodeString(<><br/></>, [piName, siCompany, piPhone, piEmail])}
      </Infoblock>
      <Infoblock title="Shipping Address">
        {arrayToNodeString(<><br/></>, [siName, arrayToNodeString(', ', [siStreet, siState, siZip, siCountry]) ])}
      </Infoblock>
      <Infoblock title="Order Summary">
        <div className="flex justify-between">
          <div>Subtotal:</div><div className="w-[70px]">{priceFormatter(subtotalPrice)}</div>
        </div>
        <div className="flex justify-between">
          <div>Shipping::</div><div className="w-[70px]">{priceFormatter(shipping)}</div>
        </div>
        <div className="flex justify-between">
          <div>Sales Tax:</div><div className="w-[70px]">{priceFormatter(taxes)}</div>
        </div>
        <div className="flex justify-between font-bold w-[200px]">
          <div>Total Amount:</div><div className="w-[70px]">{priceFormatter(totalPrice)}</div>
        </div>
      </Infoblock>
    </div>
    <div className="flex">
      <div><ButtonViewInvoice /></div>
      
    </div>
  </div>
);


export default OrderDetails;
