import { FC } from "react";
import Logo from "../../components/Logo/Logo";
import { icons } from "../../icons/IconSet";
import useTicket from "../../hooks/useTicket";

const Header: FC =() => {
  const { handleExit } = useTicket();

  return (
    <div className="flex mx-auto w-full lg:w-max-[1100px] border-border_color border-b-[1px] pb-4 sm:pb-6 justify-between">
      <Logo />
      <div
        tabIndex={-1}
        role="button"
        onClick={handleExit}
        className="trans transition-all hover:text-primary p-2 flex gap-[5px] items-center text-md"
      >
        Exit
        {icons('sign-out', { width: '14px' })}          
      </div>
    </div>
  );
};

export default Header;