import { FC } from "react";
import { icons } from "../icons/IconSet";
import { ITicket } from "../model/ITicket";
import parseISOToString from "../utils/parseISOToString";
import React from "react";

export interface ITimeLineItem {
  status: 'passed' | 'ahead',
  title: string,
  description?: string, 
}

const TimeLineVertical: FC<{ ticket?: ITicket | null }> = ({ ticket }) => (
  <div className="flex flex-col">
    {!ticket?.statusLog?.length
      ? <TimeLineVerticalItem />
      : (ticket?.statusLog?.map(({ id, created, value, type }, i) => (
        <React.Fragment key={id}>
          <TimeLineVerticalItem
            status={!type ? 'passed' : 'ahead'}
            title={value || ''}
            description={parseISOToString(created)}
          />
          {!!ticket?.statusLog?.[i + 1] && (
            <div className={`
              relative z-0 left-[15px] w-[5px] h-[40px] 
              ${{
                unknown: 'bg-gray-300',
                ahead: 'bg-[#DF9F23]',
                passed: 'bg-primary',
              }[!ticket?.statusLog[i + 1].type ? 'passed' : 'ahead']}
            `}/>
          )}
        </React.Fragment>
      )))
    }
  </div>  
);

const TimeLineVerticalItem : FC<{
  status?: 'passed' | 'ahead' | 'unknown',
  title?: string,
  description?: string,
}> = ({
  status = 'unknown',
  title = 'Unknown',
  description = '',
}) => (
  <div className="flex gap-2 h-[32px] overflow-visible items-center">
    <div className={`
      relative z-10 flex rounded-[50%] w-[35px] h-[35px] items-center justify-center color-white
      ${{
        unknown: 'bg-gray-100',
        ahead: 'bg-[#DF9F23]',
        passed: 'bg-primary',
      }[status]}
    `}>
      {{
        unknown: icons('FileQuestion', { stroke: 'black', width: '16px' }),
        ahead: icons('triangle-alert', { stroke: 'white', width: '16px' }),
        passed: icons('ok', { stroke: 'white', width: '16px' }),
      }[status]}
    </div>
    <div className="flex flex-col">
      <div className={`
        font-bold
        ${{
          unknown: 'text-gray-500',
          ahead: 'text-[#DF9F23]',
          passed: 'text-primary',
        }[status]}
      `}>
        {title}
      </div>
      <div className="text-sm text-gray-500">{description}</div>
    </div>
  </div>
);

export default TimeLineVertical;
