export default function packToFormData({
  request,
  requestTitle = 'request',
  attachments,
  attachmentsTitle = 'attachments',
} : {
  request: Record<string, any>,
  attachments?: File[],
  requestTitle?: string,
  attachmentsTitle?: string,
}): FormData {
  const formData = new FormData();

  formData.append(requestTitle, new Blob([JSON.stringify(request)], { type: "application/json" }));
  (attachments?.length
    ? attachments.forEach((file) => formData.append(`${attachmentsTitle}[]`, file))
    : formData.append(`${attachmentsTitle}[]`, new Blob([''])));  

    return formData;
}