const msgs = {
  'empty order id': 'Please, input order id',
  'empty zip': 'Please, input zip',
  'short zip': 'Please, input zip-code',
  'empty name': 'Please, input full name',
  'failed to sign in': 'failed to sign in',
  'empty message': 'Please, input message',
  'failed to send contact message': 'Failed to send contact message',
  'contact message was sended successfully': 'Contact message was sended successfully',
  'failed to send warranty claim': 'Failed to send warranty claim',
  'warranty claim was sended successfully': 'Warranty claim was sended successfully',
  'empty reason': 'Empty reason',
  'failed to send return request': 'Failed to send return request',
  'return request was sended successfully': 'Return request was sended successfully',
  'message was sended successfully': 'The message was sended successfully',
  'cancel order request was sended successfully': 'The cancel order request was sended successfully',
  'failed to send cancel order request': 'Failed to send cancel order request',
  'the order was successfully refreshed': 'The order was successfully refreshed',
  'failed to refresh the order': 'Failed to refresh the order',
};

export const msg = (key: keyof typeof msgs, options?: Record<string, string>): string => 
  options
    ? Object.entries(options).reduce((acc, [key, value]) =>
      acc.replaceAll(`{{${key}}}`, value), msgs[key])
    : msgs[key]; 
