import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '.';
import { ITicketPriority, ITicketStatus, ITicketType } from '../model/ITicket';
import { IDictionary, IVendor, IVendorRole } from '../model/ITicketCommon';

export interface ITicketCommonState {
  ticketTypes?: ITicketType[];
  ticketStatuses?: ITicketStatus[];
  ticketMessageTypes?: string[];
  ticketPriorities?: ITicketPriority[];
  vendorRoles?: IVendorRole[];
  dictionary?: IDictionary;
  vendors?: IVendor[];
}
const initialState: ITicketCommonState = {
  ticketTypes: undefined,
  ticketStatuses: undefined,
  ticketMessageTypes: undefined,
  ticketPriorities: undefined,
  vendorRoles: undefined,
  dictionary: undefined,
  vendors: undefined,
};

const ticketCommonSlice = createSlice({
  name: 'ticketCommonSlice',
  initialState,

  reducers: {
    setCommonState: (state: ITicketCommonState, action: PayloadAction<ITicketCommonState>) => {
      state.ticketTypes = action.payload.ticketTypes;
      state.ticketStatuses = action.payload.ticketStatuses;
      state.ticketMessageTypes = action.payload.ticketMessageTypes;
      state.ticketPriorities = action.payload.ticketPriorities;
      state.vendorRoles = action.payload.vendorRoles;
      state.dictionary = action.payload.dictionary;
      state.vendors = action.payload.vendors;      
    },
    removeCommonState: (state: ITicketCommonState) => { state = initialState; },
  },
});

// Reducers
export default ticketCommonSlice.reducer;
export const {
  setCommonState,
  removeCommonState,
} = ticketCommonSlice.actions;

// Selectors
export const getTicketsTypes = () => (state: RootState) => state.ticketCommonSlice.ticketTypes;
export const getTicketStatuses = () => (state: RootState) => state.ticketCommonSlice.ticketStatuses;
export const getTicketMessageTypes = () => (state: RootState) => state.ticketCommonSlice.ticketMessageTypes;
export const getTicketsPriorities = () => (state: RootState) => state.ticketCommonSlice.ticketPriorities;
export const getTicketsVendorRoles = () => (state: RootState) => state.ticketCommonSlice.vendorRoles;
export const getDictionary = () => (state: RootState) => state.ticketCommonSlice.dictionary;
export const getTicketsVendors = () => (state: RootState) => state.ticketCommonSlice.vendors;
