import { FC, PropsWithChildren, useState } from "react";

const OrderLayout: FC<PropsWithChildren> = ({ children }) => (
<div className="bg-white h-full overflow-hidden">
  <main className="p-3 h-full transition-all duration-300">
    {children}
  </main>
</div>
);


export default OrderLayout;
