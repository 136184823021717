import { FC } from "react";
import { NavLink } from "react-router-dom";
import { IFile } from "../../model/IFile";
import { ITicketMessageAttachment } from "../../model/ITicket";

const LoadedFileList: FC<{ files: ITicketMessageAttachment[] }> = ({ files }) => !files?.length
  ? null
  : (
    <div className="flex flex-wrap gap-2">
      {files.map(({ name, path }) => (
        <NavLink
          key={`loaded-file-${name}`}
          to={path}
          className="text-primary underline hover:text-primary_dark"
          target="_blank"
        >
          {name}
        </NavLink>
      ))}
    </div>
  )

export default LoadedFileList;
