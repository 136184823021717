export default function parseISOToString(iso: string): string {
  if (!iso) return '';

  const b = iso.split(/\D+/);
  const d = new Date(Date.UTC(+b[0], +b[1], +b[2], +b[3], +b[4], +b[5], +b[6]));

  return `${addZero(d.getDate())}/${addZero(d.getMonth())}/${d.getFullYear()} at ${addZero(d.getHours())}:${addZero(d.getMinutes())}`; 
}

const addZero = (num: number): string => `0${num}`.slice(-2);
