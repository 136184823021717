import { FC, useEffect, useState } from 'react';
import { WrapInLayout } from '../layouts';
import { IOrderSignInState } from '../model/IOrderSignIn';
import OrderSignInForm from '../components/OrderSignInForm';
import useOrder from '../hooks/useOrder';
import { useNavigate } from 'react-router';
import AuthParamsHistory from '../components/AuthParamsHistory';

const initState: IOrderSignInState = {
  orderId: '',
  zip: '',
  name: '',
};

const OrderSignInPage: FC = () => {
  const [orderSignInState, setOrderSignInState] = useState<IOrderSignInState>(initState);
  const [orderSignInStateErr, setOrderSignInStateErr] = useState<Partial<Record<keyof IOrderSignInState, string>>>();
  const [loading, setLoading] = useState<boolean>();
  const { orderCheckParams, storageAuthParamsHistory } = useOrder();
  const navigate = useNavigate();

  // -- If there are order auth params - go to order
  const { isOrderAuthInfo } = useOrder();
  useEffect(() => { isOrderAuthInfo() && navigate('/'); } , []);
  if (isOrderAuthInfo()) return null;

  const handleOrderSingIn = async () => {  
    setLoading(() => true);
    setOrderSignInStateErr(() => undefined);

    const errs = await orderCheckParams(orderSignInState);
    if (errs) setOrderSignInStateErr(() => errs);
    else {
      storageAuthParamsHistory(orderSignInState);
      navigate(`${process.env.REACT_APP_BASE_URL}order`);      
    }
    setLoading(() => false);
  }; 

  return (
    <WrapInLayout title="Enter Order parameters" layout="center">
      <OrderSignInForm
        state={orderSignInState}
        stateErr={orderSignInStateErr}
        setState={setOrderSignInState}
        loading={loading}
        handleOrderSingIn={handleOrderSingIn}
      />
      <div className=" flex justify-center mt-10">
        <AuthParamsHistory setState={setOrderSignInState} />
      </div>      
    </WrapInLayout> 
  );
}

export default OrderSignInPage;
