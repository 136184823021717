
// --
// -- Determine the form schema

import toast from "react-hot-toast";
import { z } from "zod";
import { getErrorFromCatch } from "../api/api";
import { orderApi } from "../api/order.api";
import { IOrderSignInState } from "../model/IOrderSignIn";
import { msg } from "../msg";
import StorageService from "../services/storage.service";
import { getOrder, setOrder } from "../store/orderSlice";
import checkFormStateByZod from "../utils/checkFormStateByZod";
import { useTypedDispatch } from "./useTypedDispatch";
import { useTypedSelector } from "./useTypedSelector";
import { SETTING } from "../setting";

// --
const orderSignInscheme = z.object({
  orderId: z
    .string()
    .min(1, msg('empty order id')),
  zip: z
    .string({ required_error: msg('empty zip') })
    .min(3, msg('short zip')),
  name: z
    .string()
    .min(1, msg('empty name')),

} as Record<keyof IOrderSignInState, any>);

export default function useOrder () {
  const dispatch = useTypedDispatch();

  const setOrderAuthParams = (data: IOrderSignInState) => Object.entries(data).forEach(([key, value]) =>
    StorageService.setItem(key, value));

  const getOrderAuthParams = (): IOrderSignInState => ({
    orderId: StorageService.getItem('orderId'),
    zip: StorageService.getItem('zip'),
    name: StorageService.getItem('name'),
  });

  const isOrderAuthInfo = (): boolean =>
    !!StorageService.getItem('orderId') && !!StorageService.getItem('zip') && !!StorageService.getItem('name');

  const removeOrderAuthParams = (): void => {
    StorageService.removeItem('orderId');
    StorageService.removeItem('zip');
    StorageService.removeItem('name');
  };

  const filteredAuthParamsHistory = (arrParams?: Record<string, string>[]): IOrderSignInState[] => 
    (arrParams || []).reduce((acc, { orderId, zip, name }) => [
      ...acc,
      ...(orderId && zip && name
        ? [{ orderId, zip, name }]
        : []
      )     
    ], [] as IOrderSignInState[])
    
  const getAuthParamsHistory = (): IOrderSignInState[] => {
    let currentHistory = StorageService.getItem(SETTING.authHistoryStorageTitle);

    try {
      currentHistory = JSON.parse(currentHistory);
      return filteredAuthParamsHistory(JSON.parse(StorageService.getItem(SETTING.authHistoryStorageTitle)));
    } catch (err) {
      StorageService.removeItem(SETTING.authHistoryStorageTitle);
    }

    return [];
  }


  const storageAuthParamsHistory = (param: IOrderSignInState) => {
    const currentHistoryObj = getAuthParamsHistory();
    StorageService.setItem(SETTING.authHistoryStorageTitle, JSON.stringify([
      param,
      ...(currentHistoryObj.filter(({ orderId }) => orderId !== param.orderId) || [])
    ]));    
  }

  // --
  // -- API methods
  // --
  const [tryOrderSignIn] = orderApi.useLazySingInOrderQuery();

  const orderCheckParams = async (data: IOrderSignInState): Promise<null | Partial<Record<keyof IOrderSignInState, string>>> => {
    // -- Client validation form
    const errs = checkFormStateByZod(orderSignInscheme, data);
    if (errs) return errs;

    try {
      const order = await tryOrderSignIn(data).unwrap();
      setOrderAuthParams(data);
      dispatch(setOrder(order));
      return null;
    } catch (err) {
      toast.error(`${msg('failed to sign in')} ${getErrorFromCatch(err)}`);           
      return { orderId: getErrorFromCatch(err) };
    }    
  };

  return {
    orderCheckParams,
    
    isOrderAuthInfo,
    setOrderAuthParams,
    getOrderAuthParams,
    removeOrderAuthParams,

    storageAuthParamsHistory,
    getAuthParamsHistory,
  }
}