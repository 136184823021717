import { FC } from 'react';
import { useParams } from 'react-router';
import OrderHeader from '../components/order/OrderHeader';
import OrderInfo from '../components/order/OrderInfo';
import OrderTabs from '../components/order/OrderTabs';
import { useTypedSelector } from '../hooks/useTypedSelector';
import { WrapInLayout } from '../layouts';
import { getOrder } from '../store/orderSlice';

export type IOrderTabs = 'order' | 'shipment' | 'warranty' | 'return' | 'cancel' | 'contact';

const OrderPage: FC = () => {
  const { tab } = useParams();
  
  // const dispatch = useTypedDispatch();
  // const navigate = useNavigate();
  const order = useTypedSelector(getOrder());
  // const [getOrderApi] = orderApi.useLazyGetOrderQuery();

  // useEffect(() => {
  //   !order && (async() => {
  //     const { data } = await getOrderApi({});
  //     if (!data?.id) return navigate('/ordersignin');
  //     dispatch(setOrder(data));
  //   })();
  // }, []);

  return (
    <WrapInLayout title="Order Information" layout="col1">
      {!order
        ? null
        : (
          <div className="w-full lg:w-max-[1100px] mx-auto flex flex-col gap-4">
            <div className='sm:h-[101px]'><OrderHeader /></div>
            <div className=''><OrderInfo /></div>
            <main><OrderTabs tab={tab as IOrderTabs} /></main>
          </div>
        )
      }      

    </WrapInLayout>
  );
};

export default OrderPage;
