import { ITicket } from '../model/ITicket';
import api, { paramsWithOrderAuth } from './api';

export const ticketApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCommon: build.query<any, {}>({
      query: () => ({
          url: '/tickets/common',
        }),
    }),
    setMsg: build.mutation<ITicket, { ticketId: string, formData: FormData }>({
      query: ({ ticketId, formData }) => ({
          url: `/tickets-public/${ticketId}`,
          method: 'POST',
          body: formData,
        }),
        invalidatesTags: ['Tickets'], 
    }),
    setContactUs: build.mutation<ITicket, FormData>({
      query: (body) => ({
          url: '/tickets-public/contactus',
          method: 'POST',
          body,
        }),
        invalidatesTags: ['Tickets'], 
    }),
    setWarranty: build.mutation<ITicket, FormData>({
      query: (body) => ({
          url: '/tickets-public/warranty',
          method: 'POST',
          body,
        }),
        invalidatesTags: ['Tickets'], 
    }),
    setReturn: build.mutation<ITicket, FormData>({
      query: (body) => ({
          url: '/tickets-public/return',
          method: 'POST',
          body,
        }),
        invalidatesTags: ['Tickets'], 
    }),
    setCancel: build.mutation<ITicket, FormData>({
      query: (body) => ({
          url: '/tickets-public/cancel',
          method: 'POST',
          body,
        }),
        invalidatesTags: ['Tickets'], 
    }),
    getContactUs: build.query<ITicket, {}>({
      query: () => ({
          url: '/tickets-public/contactus',
          params: paramsWithOrderAuth(),
        }),
        // providesTags: ['Tickets'],
    }), 
    getTickets: build.query<ITicket[], {}>({
      query: () => ({
          url: '/tickets-public',
          params: paramsWithOrderAuth(),
        }),
        providesTags: ['Tickets'],
    }), 
  }),
});
