import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '.';
import { IOrder } from '../model/IOrder';


export interface IOrderInitState {
  order?: IOrder,
  activeOrderItemId?: number,
}

const orderSlice = createSlice({
  name: 'orderSlice',
  initialState: {
    order: undefined,
    activeOrderItemId: undefined,
  } as IOrderInitState,

  reducers: {
    setOrder: (state: IOrderInitState, action: PayloadAction<IOrder>) => {
      state.order = action.payload;
      state.activeOrderItemId = action.payload.orderItems?.[0]?.id;
    },
    
    setActiveOrderItemId: (state: IOrderInitState, action: PayloadAction<number>) => {
      state.activeOrderItemId = ((state?.order?.orderItems || []).find(({ id }) => id === action.payload))?.id;
    },
    
    removeOrder: (state: IOrderInitState) => {
      state.order = undefined;
      state.activeOrderItemId = undefined;
    },
  },
});

// Reducers
export default orderSlice.reducer;
export const {
  setOrder,
  removeOrder,
  setActiveOrderItemId,
} = orderSlice.actions;

// Selectors
export const getOrder = () => (state: RootState) => state.orderSlice.order;
export const getActiveOrderItemId = () => (state: RootState) => state.orderSlice.activeOrderItemId;
export const getActiveOrderItem = () => (state: RootState) => (state.orderSlice.order?.orderItems || []).find(({ id }) => id === state.orderSlice.activeOrderItemId);
