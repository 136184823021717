import { combineReducers, configureStore } from '@reduxjs/toolkit';
import api from '../api/api';
import orderSlice, { IOrderInitState } from './orderSlice';
import ticketSlice, { ITicketInitState } from './ticketSlice';
import ticketCommonSlice, { ITicketCommonState } from './ticketCommonSlice';

export interface IInitReduxState {
  orderSlice?: IOrderInitState;
  ticketSlice?: ITicketInitState;
  ticketCommonSlice?: ITicketCommonState;
}

const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  orderSlice,
  ticketSlice,
  ticketCommonSlice,
});

// -- Redux store with initial state (for testing)
export const createReduxStore = (preloadedState?: IInitReduxState) =>
  configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware),
    preloadedState: preloadedState || {},
  });

export const store = createReduxStore();

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
