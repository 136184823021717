/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useHandleField } from "../../hooks/useHandleField";
import useTicket, { IMsgForm } from "../../hooks/useTicket";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { ITicket } from "../../model/ITicket";
import { msg } from "../../msg";
import { getTickets } from "../../store/ticketSlice";
import Loading from "../common/Loading";
import TicketMessage from "../tickets/TicketMessage";
import TicketMessages from "../tickets/TicketMessages";
import SubTitle from "../title/SubTitle";
import Title from "../title/Title";

export interface IContactForm {
  message: string,
  attachments?: File[],
}

const OrderContact: FC = () => {
  const tickets = useTypedSelector(getTickets());
  const { addContact, addMsg } = useTicket();
  const [loading, setLoading] = useState<boolean>(false);   

  // --
  // -- Add Ticket flow
  // --
  const initReturnForm: IContactForm = {
    message: '',
    attachments: [],
  };

  const [state, setState] = useState<IContactForm>(initReturnForm);
  const [stateErr, setStateErr] = useState<Partial<Record<keyof IContactForm, string>>>(); 
  const handleField = useHandleField(setState);

  const handleAddTicket = async () => {
    setLoading(() => true);
    setStateErr(() => undefined);
    const errs = await addContact(state);
    if (errs)
      setStateErr(() => errs);
    else {
      toast.success(msg('contact message was sended successfully'));      
      setState(() => initReturnForm);
    }
    setLoading(() => false);
  };  


  // --
  // -- Edit the existed ticket flow
  // --
  const [currentTicket, setcurrentTicket] = useState<ITicket | null>();
  const { searchTicketByTypeIds } = useTicket(); 
  useEffect(() => {    
    setcurrentTicket(() => searchTicketByTypeIds({
      typeIds: [5],
      ticketArr: tickets,
    }))}, [tickets]);  

  const initMsgForm: IMsgForm = {
    message: '',
    ticketId: currentTicket?.id ? String(currentTicket.id) : '',
  };

  const [stateMsg, setStateMsg] = useState<IMsgForm>(initMsgForm);
  const [stateMsgErr, setStateMsgErr] = useState<Partial<Record<keyof IMsgForm, string>>>();
  const handleMsgField = useHandleField(setStateMsg);

  useEffect(() => {
    setStateMsg((prevState) => ({
      ...prevState,
      ticketId: currentTicket?.id ? String(currentTicket.id) : '',
    }));
  }, [currentTicket?.id]);
  
  const handleAddMsg = async () => {
    setLoading(() => true);
    setStateMsgErr(() => undefined);
    const errs = await addMsg(stateMsg);   
    if (errs)
      setStateMsgErr(() => errs);
    else {
      toast.success(msg('message was sended successfully'));      
      setStateMsg(() => initMsgForm);
    }
    setLoading(() => false);
  };

  return currentTicket === undefined
    ? <Loading />
    : (
      <div className={`flex flex-col gap-4 ${!!loading && 'animate-pulse'}`}>
        <Title>Contact Us</Title>

        {currentTicket === null
          ? (
            <>
              <SubTitle>Please describe the inquiry in detail (required)</SubTitle>
              <p>
                For general inquires and topics that are not covered by Warranties, Returns, and Cancellations
                please fill out the form below to contact our Customer Service team.
              </p>

              <TicketMessage
                label="Inquiry"
                name="details"
                placeholder="Please describe in detail"
                value={state.message}
                handle={handleField('message')}
                error={stateErr?.message || ''}
                disabled={loading}
                setFileState={(attachments: File[]) => setState((prevState) => ({
                  ...prevState,
                  attachments,
                }))}
                files={state.attachments}
                handleSend={handleAddTicket}
              />
            </>
          ) : (
            <>
            {currentTicket?.messages?.length && (
                <div className="mt-6">
                  <div className="mb-4 font-semibold">Messages</div>
                  <TicketMessages ticket={currentTicket} />
                </div>
              )}
              <TicketMessage
                label={ !currentTicket?.messages?.length ? 'Additional Details' : ''}
                name="details"
                placeholder={ !currentTicket?.messages?.length ? 'Please describe in additional detail' : 'Message Customer Support...'}
                value={stateMsg.message}
                handle={handleMsgField('message')}
                error={stateMsgErr?.message || ''}
                disabled={loading}
                setFileState={(attachments: File[]) => setStateMsg((prevState) => ({
                  ...prevState,
                  attachments,
                }))}
                files={stateMsg.attachments}
                handleSend={handleAddMsg}
                butTitle={ !currentTicket?.messages?.length ? 'Continue' : 'Send Message'}
              />              
            </>
          )        
        }
      </div>
    );
};

export default OrderContact;
