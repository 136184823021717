import { FC, PropsWithChildren} from "react";
import Header from "./Header";

const Col1Layout: FC<PropsWithChildren> = ({ children }) => (
  <div className="h-full stylledScroll min-h-[100vh] bg-white w-full max-w-[1100px] mx-auto">
    <header className="flex bg-white flex-col py-4 px-4 sm:px-6 lg:px-8 sticky top-0 z-50">
      <Header />
    </header>
    <main>
      <div className="flex flex-col py-2 sm:py-4 px-4 sm:px-6 lg:px-8">   
        {children}
      </div>
    </main>
</div>
);

export default Col1Layout;
