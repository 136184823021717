import { FC, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useHandleField } from "../../hooks/useHandleField";
import useTicket, { IMsgForm } from "../../hooks/useTicket";
import { IOrderItem } from "../../model/IOrder";
import { ITicket } from "../../model/ITicket";
import { msg } from "../../msg";
import { getTickets } from "../../store/ticketSlice";
import Loading from "../common/Loading";
import TicketMessage from "../tickets/TicketMessage";
import TicketMessages from "../tickets/TicketMessages";
import SubTitle from "../title/SubTitle";
import Title from "../title/Title";
import OrderItem from "./OrderItem";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import TimeLineVertical from "../TimeLineVertical";

export interface IWarrantyForm {
  additionalDetails: string,
  attachments?: File[],
  orderItemId: number,
}

const OrderWarranty: FC<IOrderItem> = ({ ...activeOrderItem }) => {
  const tickets = useTypedSelector(getTickets());
  const { addWarranty, addMsg } = useTicket();
  const [loading, setLoading] = useState<boolean>(false);

  // --
  // -- Add Ticket flow
  // --
  const initWarrantyForm: IWarrantyForm = {
    additionalDetails: '',
    attachments: [],
    orderItemId: activeOrderItem.id,
  };

  const [state, setState] = useState<IWarrantyForm>(initWarrantyForm);
  const [stateErr, setStateErr] = useState<Partial<Record<keyof IWarrantyForm, string>>>(); 
  const handleField = useHandleField(setState);

  const handleAddTicket = async () => {
    setLoading(() => true);
    setStateErr(() => undefined);
    const errs = await addWarranty(state);
    if (errs)
      setStateErr(() => errs);
    else {
      toast.success(msg('warranty claim was sended successfully'));      
      setState(() => initWarrantyForm);
    }
    setLoading(() => false);
  };  


  // --
  // -- Edit the existed ticket flow
  // --
  const [currentTicket, setcurrentTicket] = useState<ITicket | null>();
  const { searchTicketByTypeIds } = useTicket(); 
  useEffect(() => {    
    setcurrentTicket(() => searchTicketByTypeIds({
      typeIds: [1],
      searchOrderItemId: activeOrderItem?.id,
      ticketArr: tickets,
    }));

    setState((prevState) => ({
      ...prevState,
      orderItemId: activeOrderItem.id,
    }));  
  }, [activeOrderItem?.id, tickets]);  

  const initMsgForm: IMsgForm = {
    message: '',
    ticketId: currentTicket?.id ? String(currentTicket.id) : '',
  };

  const [stateMsg, setStateMsg] = useState<IMsgForm>(initMsgForm);
  const [stateMsgErr, setStateMsgErr] = useState<Partial<Record<keyof IMsgForm, string>>>();
  const handleMsgField = useHandleField(setStateMsg);

  useEffect(() => {
    setStateMsg((prevState) => ({
      ...prevState,
      ticketId: currentTicket?.id ? String(currentTicket.id) : '',
    }));
  }, [currentTicket?.id]);
  
  const handleAddMsg = async () => {
    setLoading(() => true);
    setStateMsgErr(() => undefined);
    const errs = await addMsg(stateMsg);   
    if (errs)
      setStateMsgErr(() => errs);
    else {
      toast.success(msg('message was sended successfully'));      
      setStateMsg(() => initMsgForm);
    }
    setLoading(() => false);
  };

  return currentTicket === undefined
    ? <Loading />
    : (
      <div className={`flex flex-col gap-4 ${!!loading && 'animate-pulse'}`}>
        <Title>Warranty Claim</Title>    
        <OrderItem {...activeOrderItem} size="medium" />
        
        {!!currentTicket && (
          <>
            <SubTitle>Warranty Claim Status</SubTitle>
            <TimeLineVertical ticket={currentTicket} />
          </>
        )}
        
        {currentTicket === null
          ? (
            <>              
              <SubTitle>Please review the Do’s & Don’ts before submitting a Warranty Claim</SubTitle>
              <div>
                <ul className="list-disc ml-8 mb-2">
                  <li>Vehicle wasn’t used in a fleet, commercial, ride app, delivery, or other commercial means.</li>
                  <li>Part is used where the part or vehicle has been modified beyond the intended or recommended use by the manufacturer.</li>
                  <li>Failure of the part is due to abuse, modifications, or misuse of the part or vehicle.</li>
                  <li>Vehicle is involved in an accident.</li>
                  <li>Part is shipped, installed, or used outside of the United States.</li>
                  <li>For items with a heat tab is installed; if the tab center is melted, signifying that the part was run overheated.</li>
                  <li>Not following the installation requirements.</li>
                  <li>Part was removed from car before inspection.</li>
                  <li>Vehicle is not at a shop (ASE certified) if there is a need for an inspection.</li>
                </ul>
              </div>
              <SubTitle>Please add any details and upload any supporting photos (required)</SubTitle>
              <div>We may require additional information like photos, VIN of your vehicle, mileage (odometer)</div>

              <TicketMessage
                label="Issue Details"
                name="details"
                placeholder="Please describe in detail"
                value={state.additionalDetails}
                handle={handleField('additionalDetails')}
                error={stateErr?.additionalDetails || ''}
                disabled={loading}
                setFileState={(attachments: File[]) => setState((prevState) => ({
                  ...prevState,
                  attachments,
                }))}
                files={state.attachments}
                handleSend={handleAddTicket}
              />
            </>
          ) : (
            <>
              {currentTicket?.messages?.length && (
                <div className="mt-6">
                  <div className="mb-4 font-semibold">Messages</div>
                  <TicketMessages ticket={currentTicket} />
                </div>
              )}
              <TicketMessage
                label={ !currentTicket?.messages?.length ? 'Additional Details' : ''}
                name="details"
                placeholder={ !currentTicket?.messages?.length ? 'Please describe in additional detail' : 'Message Customer Support...'}
                value={stateMsg.message}
                handle={handleMsgField('message')}
                error={stateMsgErr?.message || ''}
                disabled={loading}
                setFileState={(attachments: File[]) => setStateMsg((prevState) => ({
                  ...prevState,
                  attachments,
                }))}
                files={stateMsg.attachments}
                handleSend={handleAddMsg}
                butTitle={ !currentTicket?.messages?.length ? 'Continue' : 'Send Message'}
              />
            </>
          )        
        }
      </div>
    );
};

export default OrderWarranty;
