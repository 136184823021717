import { FC } from "react";
import { icons } from "../../icons/IconSet";

const FileList: FC<{
  files: File[],
  setFiles?: (files: File[]) => unknown,
  disabled?: boolean,
}> = ({
  files,
  setFiles,
  disabled = false,
}) => {
  const handleDeleteFile = (indx: number) => () => !disabled && setFiles && files?.[indx] && setFiles([
    ...files.slice(0, indx),
    ...files.slice(indx + 1),
  ]);

  return !files?.length
  ? null
  : (
    <div 
      className={`flex flex-col gap-2 ${disabled ? 'opacity-50' : ''}`}
    >
      {files.map((file, i) => (
        <div key={`${file.name}-${i}`} className="relative flex gap-1 z-40 ">
          <div
            tabIndex={-1}
            role="button"
            onClickCapture={(e) => {
              handleDeleteFile(i)();
              e.stopPropagation();
            }}
          >
            {icons('x', { width: '18px' })}
          </div>          
          {file.name}
          {/* <div
            className="absolute right-[-7px] top-[-6px] bg-red-400 hover:bg-red-500 transition-all text-white rounded-[50%] p-[1px] cursor-pointer"
            tabIndex={-1}
            role="button"
            onClick={handleDeleteFile(i)}
          >
            {icons('x', { width: '12px' })}
          </div> */}
        </div>
      ))}
    </div>
  );
}

export default FileList;
