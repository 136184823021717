import { IOrder } from '../model/IOrder';
import { IOrderSignInState } from '../model/IOrderSignIn';
import api, { paramsWithOrderAuth } from './api';

export const orderApi = api.injectEndpoints({
  endpoints: (build) => ({
    singInOrder: build.query<any, IOrderSignInState>({
      query: (params) => ({
          url: '/ordermanagement',
          params,
        }),
    }),
    getOrder: build.query<IOrder, {}>({
      query: () => ({
          url: '/ordermanagement',
          params: paramsWithOrderAuth(),
        }),
    }),    
  }),
});
