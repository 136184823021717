import { FC } from "react";
import Button from "../form/Button";
import { useNavigate } from "react-router";

const ButtonResendInvoice: FC = () => {
  const navigate = useNavigate();  
  return (
    <Button
      variant="outline"
      size="small"
      onClick={() => navigate(`${process.env.REACT_APP_BASE_URL}order/invoice`)}
    >
      Resend Invoice
    </Button>
  );
}

export default ButtonResendInvoice;
