import { useEffect } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { orderApi } from './api/order.api';
import { ticketApi } from './api/ticket.api';
import { useTypedDispatch } from './hooks/useTypedDispatch';
import { siteMap } from './routing/siteMap';
import { setOrder } from './store/orderSlice';
import { setTickets } from './store/ticketSlice';
import { setCommonState } from './store/ticketCommonSlice';

export default function App() { 
  const dispatch = useTypedDispatch();

  const {
    data: common,
    isError: isErrorCommon,
    isSuccess: isSuccessCommon,
  } = ticketApi.useGetCommonQuery({});
  useEffect(() => { common && dispatch(setCommonState(common)) }, [common]);

  const {
    data: order,
    isError: isErrorOrder,
    isSuccess: isSuccessOrder,
  } = orderApi.useGetOrderQuery({});
  useEffect(() => { order && dispatch(setOrder(order)) }, [order]);

  const {
    data: tickets,
    isError: isErrorTickets,
    isSuccess: isSuccessTickets,
  } = ticketApi.useGetTicketsQuery({}, { skip: !order });
  useEffect(() => { tickets && dispatch(setTickets(tickets)) }, [tickets]); 

  return (isErrorOrder || isSuccessOrder)
    ? (
      <RouterProvider
        router={createBrowserRouter(siteMap)}
        fallbackElement={<> </>}
      />
    ) : null;
}
